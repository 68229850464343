import React from "react";
import {
  Button,
  Link as MuiLink,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { RoutePath } from "../../enums/RoutePath";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../store/hooks";
import { CustomCircle, CustomListItem } from "./ContentPage";
import { selectApiKey, selectISIN } from "../../store/status/statusSlice";
import { useGetSeriesByISINAndApiKeyQuery } from "../../store/services/loanNote";

export default function PersonalContent() {
  const apiKey = useAppSelector(selectApiKey);

  const storedISIN = useAppSelector(selectISIN);

  const { data: series } = useGetSeriesByISINAndApiKeyQuery(
    storedISIN && apiKey
      ? { ISIN: storedISIN, apiKey: apiKey }
      : skipToken
  );

  return (
    <>
      <Typography variant="h4" component="h1" color="primary.dark" align="center">
        {series?.nominee_name}
      </Typography>
      <Typography variant="h4" component="h1" color="primary.dark" align="center">
        DIRECT SUBSCRIPTION FORM
      </Typography>
      <Typography variant="subtitle1" component="p" color="primary.dark" mt={3}>
        Before completing this Application Form, please make sure to have read
        carefully and understood our standard “Subscription Services Agreement” and
        any annexes referred to therein. These documents set out and describe in
        further detail the nature of the subscription service provided to you.
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        color="text.secondary"
        letterSpacing="0.04em"
        mt={3}
        align="left"
      >
        COMPLETION AND SUBMISSION INSTRUCTIONS
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        color="primary.main"
        align="left"
        mt={3}
      >
        Introduction
      </Typography>
      <Typography variant="body1" mt={2}>
        This form (the “Subscription Form”) is for the exclusive use of applicants (the “Applicant”)
        subscribing for the {series?.nickname} (the "Notes") to be issued by {series?.issuer_name} (the "issuer")
        on the terms set out in the information memorandum relating to the Notes (the “Information Memorandum”).
      </Typography>
      <Typography variant="body1" mt={2}>
        This Subscription Form should be completed electronically. Once completed, please return the
        completed Subscription Form to us via email on {" "}
        <MuiLink href={`mailto:${series?.nominee_email}`}>
          {series?.nominee_email}
        </MuiLink>
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        color="primary.main"
        align="left"
        mt={3}
      >
        Important Notes
      </Typography>
      <List>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            All Subscription Forms will be processed on behalf of the Issuer by {series?.nominee_name}
            in its capacity as servicer of the Issuer.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            All questions in this Subscription Form must be answered, if a question is not applicable to you,
            please answer “n/a”.
            Once received, we will review the questionnaire, ask you for any
            clarifications or additional information (if required), within{" "}
            <Typography fontWeight="700" component="b">
              2 business days
            </Typography>
            , following which we will send you a unique link to yourself to
            undergo proof of identity (passport) check and proof of address
            (utility bill) check. Therefore, you are not required to send a copy
            of your passport and/or utility bill with this questionnaire as
            these will be checked electronically. Upon proof of identity and
            proof of address checks being complete, you will receive all
            documentation pertaining to our services, including this
            questionnaire, to be signed electronically.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Documents provided must not be older than 3 months.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Utility bills of mobile telephony or internet connection are not
            accepted.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            We reserve the right to request additional due diligence
            documentation (other than the documentation set out in this
            questionnaire) to satisfy our obligations under applicable
            anti-money laundering and funding of terrorism legislation and
            regulations.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Please ensure you have all the required supporting documentation
            before submitting this questionnaire.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            The contents of this questionnaire shall be treated in strict
            confidence. All information contained herein must be correct as at
            time of completion and we must be informed immediately of any
            changes thereto. In the event that incorrect information is supplied
            to us or if we are not informed of any changes, we reserve the right
            not to provide/continue providing our services to you.
          </ListItemText>
        </CustomListItem>
      </List>

      <Typography
        variant="h6"
        component="h3"
        color="primary.main"
        align="left"
        mt={3}
      >
        Disclaimer
      </Typography>

      <List
        sx={{
          fontWeight: 800,
          fontSize: 10,
          color: "primary.main",
        }}
      >
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            This document and its contents are prepared by the Issuer. This document is not an offer or solicitation in any
            jurisdiction in which such offer is not authorised. Nothing in it should be construed
            as advice and is therefore not a recommendation to buy or sell the Notes.
            Any prospective subscriber to the Notes represents that the Notes shall not be offered,
            advertised, sold or otherwise transferred, either directly or indirectly, to any person in violation of economic sanctions or wider restrictions applicable to either the Applicant or the Issuer.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            The information contained herein does not constitute an offer or invitation to purchase the
            Notes by anyone in any jurisdiction in which such offer or invitation is not authorized or
            to any person to whom it is unlawful to make such offer or invitation.
            The distribution of this document and the offering or sale of the Notes may be
            prohibited or restricted by law in some jurisdictions. The Notes may not be publicly
            offered, sold or delivered within or from the jurisdiction of any country, except in
            accordance with the applicable laws and other legal provisions, and provided further
            that the Issuer does not incur any obligations. The Issuer has not undertaken any steps,
            nor will the Issuer undertake any steps, aimed at making the public offering of the Notes or their
            possession or the marketing of offering documents related to the Notes legal in such
            jurisdiction if this requires special measures to be taken.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Investments may go up or down in value and you may lose some or all of the amount invested.
            Past performance or simulated past performance are not an indicator of future results.
            Any decision to invest should be based on a full reading of the Information Memorandum.
            In case of any discrepancy between this Subscription Form and the Information Memorandum,
            the Information Memorandum shall prevail. <br/>
            <Typography fontWeight="700" component="b">
              EEA Retail Investors: 
            </Typography>
            The Notes are not intended to be offered, sold, or otherwise
            made available to and should not be offered, sold or otherwise made available at any time
            to any retail investor (and, for the avoidance of doubt, this means any retail investor
            in the European Economic Area (“EEA”)). For these purposes, a retail investor means a person 
            who is one (or more) of: (i) a retail client as defined in point (11) of Article 4(1) of Directive
             2014/65/EU (as amended, “MiFID II”); or (ii) a customer within the meaning of Directive (EU) 2016/97 
             (the “Insurance Distribution Directive”), where that customer would not qualify as 
             a professional client as defined in point (10) of Article 4(1) of MiFID II; 
             or (iii) not a qualified investor as defined in Regulation (EU) 2017/1129 
             (as amended, the “Prospectus Regulation”). 
             Consequently, no key information document required by Regulation (EU) No 1286/2014 (as amended, the “PRIIPs Regulation”) 
             for offering or selling the Notes or otherwise making them available to retail investors in the EEA has 
             been prepared and therefore offering or selling the Notes or otherwise making them available to 
             any retail investor in the EEA may be unlawful under the PRIIPs Regulation. <br/>
            <Typography fontWeight="700" component="b">
              UK Retail Investors: 
            </Typography>
            The Notes are not intended to be offered, sold, or otherwise made
            available to and should not be offered, sold or otherwise made available at any time to any
            retail investor (and, for the avoidance of doubt, this means any retail investor in the UK).
            For these purposes, a retail investor means a person who is one (or more) of: (i) a retail
            client as defined in point (8) of Article 2 of Regulation No 2017/565 as it forms part of
            domestic law by virtue of the European Union (Withdrawal) Act 2018 (“EUWA”); or (ii) a
            customer within the meaning of the provisions of the Financial Services and Markets Act
            2000 (“FSMA”) and any rules or regulations made under the FSMA to implement Directive
            (EU) 2016/97 (the “Insurance Distribution Directive”), where that customer would not
            qualify as a professional client, as defined in point (8) of Article 2(1) of Regulation
            (EU) No 600/2014 as it forms part of domestic law by virtue of the EUWA; or (iii) not a
            qualified investor as defined in Article 2 of Regulation (EU) 2017/1129 as it forms part
            of domestic law by virtue of EUWA (the “UK Prospectus Regulation”).
            Consequently, no key information document required by Regulation (EU) No 1286/2014 as
            it forms part of domestic law by virtue of EUWA (as amended, the “UK PRIIPs Regulation”)
            for offering or selling the Notes or otherwise making them available to retail investors
            in the UK has been prepared and therefore offering or selling the Notes or otherwise making
            them available to any retail investor in the UK may be unlawful under the PRIIPs Regulation. <br/>
            <Typography fontWeight="700" component="b">
              United States of America: 
            </Typography>
            This document is not for distribution, directly or indirectly,
            in or into the United States of America (“United States”) or its possessions.
            This document is not an offer to sell securities, or the solicitation of any offer to
            buy securities, nor shall there be any offer of securities in the United States or
            in any jurisdiction in which such offer or sale would be unlawful. The Notes have not
            been and will not be registered under the U.S. Securities Act of 1933, as amended
            (“Securities Act”), and may not be offered or sold in the United States absent
            registration or exemption from registration under the Securities Act.
          </ListItemText>
        </CustomListItem>
      </List>
      <Button
        size="large"
        variant="outlined"
        component={Link}
        to={RoutePath.FiduciaryApplicationForm}
        sx={{
          display: "block",
          width: "fit-content",
          mt: 4,
          mx: "auto",
          "& a": {
            textDecoration: "none",
            color: "primary.main",
          },
        }}
      >
        I agree to the terms settled here
      </Button>
    </>
  );
}
