import React from "react";
import {
  Button,
  Link as MuiLink,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Link } from "react-router-dom";
import { RoutePath } from "../../enums/RoutePath";
import { useAppSelector } from "../../store/hooks";
import { CustomCircle, CustomListItem } from "./ContentPage";
import { selectApiKey, selectISIN, selectPersonType } from "../../store/status/statusSlice";
import { useGetSeriesByISINAndApiKeyQuery } from "../../store/services/loanNote";


export default function CorporateContent() {
  const apiKey = useAppSelector(selectApiKey);

  const storedISIN = useAppSelector(selectISIN);

  const { data: series } = useGetSeriesByISINAndApiKeyQuery(
    storedISIN && apiKey
      ? { ISIN: storedISIN, apiKey: apiKey }
      : skipToken
  );

  return (
    <>
      <Typography variant="h4" component="h1" color="primary.dark" align="center">
        {series?.nominee_name}
      </Typography>
      <Typography variant="h4" component="h1" color="primary.dark" align="center">
        DIRECT SUBSCRIPTION FORM
      </Typography>
      <Typography variant="subtitle1" component="p" color="primary.dark" mt={3}>
        Before completing this Application Form, please make sure to have read
        carefully and understood our standard “Fiduciary Services Agreement” and
        any annexes referred to therein. These documents set out and describe in
        further detail the nature of the fiduciary service provided to you.
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        color="text.secondary"
        letterSpacing="0.04em"
        mt={3}
        align="left"
      >
        COMPLETION AND SUBMISSION INSTRUCTIONS
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        color="primary.main"
        align="left"
        mt={3}
      >
        Introduction
      </Typography>
      <Typography variant="body1" mt={2}>
        {series?.nominee_name} is authorised by the {series?.nominee_country_name} Financial Services
        Authority (the “MFSA”) to act as mandatory in the holding of securities
        pursuant to Article 43(12) of the Trust and Trustees Act (
        <span>{series?.nominee_address}</span>, Chapter 331 of the Laws of {series?.nominee_country_name}).
        This effectively means that, whilst the client (the “entity” or “you”)
        would remain the beneficial owner of the relevant securities, you would
        authorise {series?.nominee_name} to hold your securities on your behalf and to act on your
        instructions in relation to such securities.
      </Typography>
      <Typography variant="body1" mt={2}>
        The purpose of this application is for you to request {series?.nominee_name} to accept its
        appointment as your mandatory to hold your securities, and to provide
        {series?.nominee_name} with information and supporting documentation in relation to such
        securities.
      </Typography>
      <Typography variant="body1" mt={2}>
        We are required by law to operate and apply anti-money
        laundering checks and procedures (including, in particular, confirmation
        of identity and source of wealth and funds) in respect of a entity.
      </Typography>
      <Typography variant="body1" mt={2}>
        This questionnaire sets out the information and documentation required
        by {series?.nominee_name} before it agrees to provide services to you. As such, this
        questionnaire sets out the information and documentation required by us
        in your respect for the purposes of considering whether to accept you as
        a client with a view to providing you with our services.
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        color="primary.main"
        align="left"
        mt={3}
      >
        Important Notes
      </Typography>
      <List>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            All questions in this questionnaire must be answered, if a question
            is not applicable to you, kindly answer “n/a”.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            This questionnaire should be completed electronically. Once
            completed, please return the completed questionnaire together with
            any supporting documentation, to us via email on{" "}
            <MuiLink href={`mailto:${series?.nominee_email}`}>
              {series?.nominee_email}
            </MuiLink>
            . Once received, we will review the questionnaire, ask you for any
            clarifications or additional information (if required), within{" "}
            <Typography fontWeight="700" component="b">
              2 business days
            </Typography>
            , following which we will send a unique link to each individual
            within the entity which must undergo proof of identity (passport)
            check and proof of address (utility bill) check. Therefore, you are
            not required to send passport and/or utility bill copies with this
            questionnaire as these will be checked electronically. Upon proof of
            identity and proof of address checks being complete, you will
            receive all documentation pertaining to our services, including this
            questionnaire, to be signed electronically by an authorized
            representative and signatory of the entity.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Section 3 of this questionnaire should be completed for each
            Individual Shareholder and Ultimate Beneficial Owner. Thus, if the
            entity is owned as to 100% by a Corporate Shareholder which, in
            turn, is owned by two Ultimate Beneficial Owners who each hold 50%,
            Section 3 is to be completed separately for each of the said two
            individuals. Please also complete and procure the execution of
            SCHEDULE 1 – PERSONAL DECLARATION of this questionnaire in relation
            to each such Individual Shareholder and Ultimate Beneficial Owner.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Section 4 of this questionnaire should be completed for each
            Corporate Shareholder. Thus, if the entity is owned as to 100% by a
            Corporate Shareholder (“CS1”) which, in turn, is owned as to 50% by
            an Individual Shareholder and as to 50% by another Corporate
            Shareholder (“CS2”), the said Section 4 is to be completed
            separately in relation to CS1 and CS2. Furthermore, please also
            complete and procure the execution of SCHEDULE 2 – CORPORATE
            DECLARATION in relation to each such Corporate Shareholder.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Once the questionnaire is reviewed by {series?.nominee_name} and comments (if any)
            provided any documentation requested pursuant to this questionnaire
            is to be sent to us either in original or as a certified copy,
            unless indicated otherwise.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Documents must not be older than 3 months.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Certified copies must be certified by persons duly authorised to
            certify documents such as warranted lawyers, notaries, and auditors.
            All certified copies must be dated.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            The certification must clearly indicate that the document is a true
            copy of the original, that the document has been seen and verified
            by the certifier and if the document being certified is a passport
            or any other official identification document containing a photo,
            then the certification should indicate that the photo is a true
            likeness of the individual.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Utility bills of mobile telephony or internet connection are not
            accepted.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Except for passports, identity cards and utility bills, if a
            document is not in English, a translated copy must be provided. The
            translation should be dated, signed and certified by an independent
            person of proven competence and qualification confirming that it is
            a faithful translation of the original. Such translation must also
            be dated.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            We reserve the right to request additional due diligence
            documentation (other than the documentation set out in this
            questionnaire) to satisfy our obligations under applicable
            anti-money laundering and funding of terrorism legislation and
            regulations.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            Please ensure all the required supporting documentation are in hand
            before submitting the questionnaire.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            The contents of this questionnaire shall be treated in strict
            confidence. All information contained herein must be correct as at
            time of completion and we must be informed immediately of any
            changes thereto. In the event that incorrect information is supplied
            to us or if we are not informed of any changes, we reserve the right
            not to provide/continue providing our services to you.
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText
            sx={{
              fontWeight: 700,
              color: "primary.main",
            }}
          >
            If additional space is required please use the comments / additional
            notes section on the last page remembering to note the question
            number alongside your answer.
          </ListItemText>
        </CustomListItem>
      </List>
      <Typography variant="h6" component="h3" color="primary.main" mt={1}>
        Key Terms
      </Typography>
      <List>
        <ListSubheader>
          <Typography color="text.primary" py={1}>
            A number of capitalised key terms are used throughout this
            questionnaire. The following part of this questionnaire seeks to
            define and explain each key term:
          </Typography>
        </ListSubheader>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            “Corporate Shareholder” means a legal person which holds, whether
            directly or indirectly, shares or similar ownership instruments or
            interests in the entity;
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            “Director” means any person who is appointed as a director of the
            entity;
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            “Individual Shareholder” means a natural person holding shares or
            similar ownership instruments or interests directly in the entity;
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            “Key Individual” means any person, not being a Director, who is
            vested with the administration or representation of the entity;
          </ListItemText>
        </CustomListItem>
        <CustomListItem>
          <ListItemIcon>
            <CustomCircle />
          </ListItemIcon>
          <ListItemText>
            “Ultimate Beneficial Owner” means a natural person who has an
            indirect ownership and/or voting interest or other participation in
            the entity through other holding companies, partnerships, trusts or
            other entities or vehicles.
          </ListItemText>
        </CustomListItem>
      </List>
      <Button
        size="large"
        variant="outlined"
        component={Link}
        to={RoutePath.FiduciaryApplicationForm}
        sx={{
          display: "block",
          width: "fit-content",
          mt: 4,
          mx: "auto",
          "& a": {
            textDecoration: "none",
            color: "primary.main",
          },
        }}
      >
        I agree to the terms settled here
      </Button>
    </>
  );
}
