import {
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SeriesNotAvailable from "../components/form-components/SeriesNotAvailable";
import SkeletonWrapper from "../components/SkeletonWrapper";
import { DynamicType } from "../enums/DynamicType";
import { PersonType } from "../enums/PersonType";
import { QueryParams } from "../enums/QueryParams";
import { RoutePath } from "../enums/RoutePath";
import { StaticContent } from "../enums/StaticContent";
import useFontLoad from "../hooks/useFontLoad";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  useGetSeriesByISINAndApiKeyQuery,
  useGetStaticContentQuery,
  useGetTypesQuery,
} from "../store/services/loanNote";
import {
  allowProductAccess,
  disallowProductAccess,
  selectApiKey,
  selectISIN,
  setISIN,
  setPersonType,
} from "../store/status/statusSlice";
import { Image } from "./terms-of-conditions/TermsOfConditions";

export default function CustomerClassification() {
  const location = useLocation();
  const queryISIN =
    new URLSearchParams(location.search).get(QueryParams.ISIN) ?? "";
  const ISIN = useAppSelector(selectISIN) || queryISIN;
  const apiKey = useAppSelector(selectApiKey);
  const dispatch = useAppDispatch();
  const pathToToC = RoutePath.ToC + "/1";
  const {
    data: staticContent,
    isError,
    isFetching,
  } = useGetStaticContentQuery();
  const { data: types, isFetching: isTypesFetching } = useGetTypesQuery();
  const personTypes = types?.[DynamicType.PersonTypes].filter(
    (type) => type.toLowerCase() !== PersonType.Corporate.toLowerCase()
  );
  const {
    data: series,
    isError: isSeriesError,
    isFetching: isSeriesFetching,
  } = useGetSeriesByISINAndApiKeyQuery(
    ISIN && apiKey ? { ISIN, apiKey } : skipToken
  );
  const hasFontLoaded = useFontLoad("Roboto");

  const skeletonArray = Array(4).fill("Financial Adviser");

  useEffect(() => {
    dispatch(setISIN(queryISIN));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (personType: string) => {
    dispatch(setPersonType(personType));
    if (
      isSeriesError ||
      personType.length === 0 ||
      personType === PersonType.Other
    ) {
      dispatch(disallowProductAccess());
      return;
    }
    dispatch(allowProductAccess());
  };

  return (
    <Container
      maxWidth="sm"
      fixed
      sx={{
        mx: "auto",
        alignItems: "center",
        minHeight: "100%",
      }}
    >
      <Dialog
        open={!queryISIN}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 1500,
        }}
        PaperProps={{
          sx: {
            alignItems: "start",
          },
        }}
      >
        <SkeletonWrapper
          isLoading={isFetching}
          alternateComponent={
            <DialogTitle id="alert-dialog-title" color="primary">
              Information of Series Is Missing
            </DialogTitle>
          }
          sx={{
            mx: 3,
          }}
        >
          <DialogTitle id="alert-dialog-title" color="primary">
            {staticContent?.[StaticContent.SeriesMissingInformationPopupTitle]}
          </DialogTitle>
        </SkeletonWrapper>
        <DialogContent
          sx={{
            pt: 0,
          }}
        >
          <SkeletonWrapper
            isLoading={isFetching}
            alternateComponent={
              <DialogContentText id="alert-dialog-description">
                Cannot go forward with the application as the information to
                find series is missing.
              </DialogContentText>
            }
            width="inherit"
          >
            <DialogContentText id="alert-dialog-description">
              {staticContent?.[StaticContent.SeriesMissingInformationPopupBody]}
            </DialogContentText>
          </SkeletonWrapper>
        </DialogContent>
      </Dialog>
      {isSeriesError && (
        <SeriesNotAvailable>
          <SkeletonWrapper
            isLoading={isFetching}
            alternateComponent={
              <DialogContentText id="alert-dialog-description">
                The nominee does not have the product you requested. Try another
                product.
              </DialogContentText>
            }
            width="inherit"
          >
            <DialogContentText id="alert-dialog-description">
              {
                staticContent?.[
                  StaticContent.SeriesNotAvailableProductMissingNominee
                ]
              }
            </DialogContentText>
          </SkeletonWrapper>
        </SeriesNotAvailable>
      )}
      <Dialog
        open
        fullWidth
        disableEnforceFocus
        aria-labelledby="customer-classification-title"
        aria-describedby="customer-classification-description"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        PaperProps={{
          sx: {
            boxShadow: 10,
          },
        }}
      >
        <SkeletonWrapper
          isLoading={isSeriesFetching || isSeriesError || !queryISIN}
          alternateComponent={
            <Image
              src={ staticContent?.brand || series?.issuer_logo}
              className="sharpen-image"
              alt="logo"
              sx={{
                width: "100%",
                height: "100%",
                mixBlendMode: "multiply",
                aspectRatio: "1",
                m: "auto",
                p: 1.5,
              }}
            />
          }
          variant="circular"
          animation={isSeriesError || !queryISIN ? false : "pulse"}
          sx={{
            height: "5rem",
            aspectRatio: "1",
            m: "1rem auto",
          }}
        >
          <Image
            src={ staticContent?.brand || series?.issuer_logo}
            className="sharpen-image"
            alt="logo"
            sx={{
              height: "7rem",
              mixBlendMode: "multiply",
              aspectRatio: "1",
              m: "auto",
              p: 1.5,
            }}
          />
        </SkeletonWrapper>
        <Divider
          sx={{
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <SkeletonWrapper
            isLoading={
              isSeriesFetching || isSeriesError || !queryISIN || !hasFontLoaded
            }
            alternateComponent={<span>Series Nickname</span>}
            animation={isSeriesError ? false : "pulse"}
          >
            {series?.nickname}
          </SkeletonWrapper>
        </Divider>
        <Grid mt={2} px={3}>
          <SkeletonWrapper
            isLoading={isFetching || isError || !hasFontLoaded}
            alternateComponent={
              <Typography
                variant="h3"
                component="h1"
                align="center"
                textTransform="uppercase"
                id="customer-classification-title"
              >
                Who are you?
              </Typography>
            }
          >
            <Typography
              variant="h3"
              component="h1"
              align="center"
              textTransform="uppercase"
              id="customer-classification-title"
            >
              {staticContent?.[StaticContent.LandingPageHeader]}
            </Typography>
          </SkeletonWrapper>
        </Grid>
        <DialogContent>
          <DialogContentText
            component="div"
            textAlign="justify"
            id="customer-classification-description"
            sx={{
              mb: 4,
              fontVariant: "all-petite-caps",
            }}
          >
            <Stack spacing={3}>
              <SkeletonWrapper
                isLoading={isFetching || isError || !hasFontLoaded}
                alternateComponent={
                  <Typography fontSize="1.1rem">
                    You have opted to apply for the selected securities via a
                    nominee service. Please note that the following pages will
                    take you through the application form to appoint the nominee
                    as your mandatory to hold the securities on your behalf and
                    to provide information about yourself/your entity to the
                    nominee.
                  </Typography>
                }
              >
                <Typography fontSize="1.1rem">
                  {staticContent?.[StaticContent.LandingPageDescription]}
                </Typography>
              </SkeletonWrapper>
              <SkeletonWrapper
                isLoading={isFetching || isError || !hasFontLoaded}
                alternateComponent={
                  <Typography fontSize="1.1rem" align="center">
                    Tell us a little about yourself, so we can introduce you to
                    the product.
                  </Typography>
                }
              >
                <Typography fontSize="1.1rem" align="center">
                  {staticContent?.[StaticContent.LandingPageIntroduction]}
                </Typography>
              </SkeletonWrapper>
            </Stack>
          </DialogContentText>
          <ButtonGroup
            fullWidth
            size="large"
            color="primary"
            variant="outlined"
            aria-label="outlined primary button group"
            disabled={isSeriesFetching || isSeriesError || !queryISIN}
            sx={{
              "& .MuiButton-root": {
                height: "100%",
                textAlign: "center",
              },
            }}
          >
            <Grid container spacing={3}>
              {isTypesFetching || !hasFontLoaded
                ? skeletonArray.map((value, i) => {
                    let warning = i % 5 === 3;
                    let success = i % 5 === 4;
                    let isWarningOrSuccess = warning || success;

                    return (
                      <Grid
                        item
                        xs={isWarningOrSuccess ? 12 : true}
                        sm={isWarningOrSuccess ? 12 : true}
                        key={i}
                      >
                        <Skeleton variant="rounded" width="100%">
                          <Button fullWidth component={Link} to={pathToToC}>
                            {value}
                          </Button>
                        </Skeleton>
                      </Grid>
                    );
                  })
                : personTypes?.map((personType, i) => {
                    let warning = i % 5 === 3;
                    let success = i % 5 === 4;
                    let isWarningOrSuccess = warning || success;

                    return (
                      <Grid
                        item
                        xs={isWarningOrSuccess ? 12 : true}
                        sm={isWarningOrSuccess ? 12 : true}
                        key={personType}
                      >
                        <Button
                          color={
                            isWarningOrSuccess
                              ? warning
                                ? "warning"
                                : "success"
                              : "primary"
                          }
                          component={Link}
                          to={pathToToC}
                          onClick={() => handleClick(personType)}
                        >
                          {personType}
                        </Button>
                      </Grid>
                    );
                  })}
            </Grid>
          </ButtonGroup>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
